import { useState, useEffect } from 'react';
import jsCookie from 'js-cookie';
import { useSnackbar } from 'notistack';

const useAuth = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(true);
  const [token, setToken] = useState(null);
  const [user, setUser] = useState(null);

  useEffect(() => {
    const storedToken = jsCookie.get('token');
    if (!storedToken) {
      setLoading(false);
      return;
    }

    let isMounted = true; // Track if component is mounted

    const fetchUserData = async () => {
      try {
        const res = await fetch('https://weed.appreconhecimento.com/user', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + storedToken,
          },
        });
        const data = await res.json();
        if (isMounted) {
          if (res.ok && data.status === 'success') {
            setToken(storedToken);
            setUser(data.data);
          } else {
            handleLogout(data.message || 'Erro ao conectar com o servidor!');
          }
        }
      } catch (error) {
        if (isMounted) {
          handleLogout('Erro ao conectar com o servidor!');
        }
      } finally {
        if (isMounted) {
          setLoading(false);
        }
      }
    };

    const handleLogout = (message) => {
      if (isMounted) {
        enqueueSnackbar(message, { variant: 'error' });
        //jsCookie.remove('token');
        //setToken(null);
        //setUser(null);
      }
    };

    fetchUserData();
    const intervalId = setInterval(fetchUserData, 5000);

    return () => {
      clearInterval(intervalId); // Cleanup interval on unmount
      isMounted = false; // Cleanup flag on unmount
    };
  }, [enqueueSnackbar]);

  const login = (newToken, newUser) => {
    jsCookie.set('token', newToken);
    setToken(newToken);
    setUser(newUser);
  };

  const logout = () => {
    jsCookie.remove('token');
    setToken(null);
    setUser(null);
  };

  return { loading, token, user, login, logout };
};

export default useAuth;